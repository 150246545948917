import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    // setTimeout(() => {
    //   const fetchUrlLinkElm = document.getElementById("fetch-url-link")

    //   if (fetchUrlLinkElm){
    //     fetchUrlLinkElm.click()
    //   }
    // }, 60000)
  }
}
