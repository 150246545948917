import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "confirmationMessage", "labelConfirmationMessage",
                     "stockLocationState", "submitButton", "cancelButton"]

  initialize(){
    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success;
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop();

      if (responseSuccess && urlPath === "destroy_inventory_boxes") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          this.cancelButtonTarget.click()
        }
      }
    })
  }

  validateForm(){
    let result = true
    const stateInput = document.querySelector(".ts-wrapper")
    const confirmationMessage = this.confirmationMessageTarget
    const textMessageValue = this.labelConfirmationMessageTarget.innerHTML

    this.confirmationMessageTarget.style.borderColor = ""
    stateInput.style.borderColor = ""

    if (this.stockLocationStateTarget.value === "") {
      stateInput.style.borderColor = "red"
      result = false
    }

    if (confirmationMessage.value !== textMessageValue){
      confirmationMessage.style.borderColor = "red"
      result = false
    }

    return result
  }

  submit(event) {
    event.preventDefault()

    if(this.validateForm()){
      this.submitButtonTarget.classList.add("disabled")
      this.submitButtonTarget.classList.remove("bg-rose-500", "hover:bg-rose-600")
      this.submitButtonTarget.classList.add("bg-rose-200", "hover:bg-rose-300")
      this.submitButtonTarget.value = "Aguarde..."
      document.querySelector("#form-destroy-inventory-boxes").requestSubmit()
    }
  }
}
