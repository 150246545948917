import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String }

  load(event) {
    const index = event.target.dataset.index
    const quantity = event.target.value
    const url = new URL(this.urlValue)
    url.searchParams.append("quantity", quantity)
    fetch(url)
      .then(response => response.json())
      .then(json => {
        const element = document.getElementById(`future-inventory-${index}`)
        element.innerHTML = Intl.NumberFormat("pt-br").format(json.data)
      })
  }
}
