import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "productCount", "inputQuantity" ]

  changeQuantity(){
    let totalQuantity = 0
    const formBulkItemsReplacement = document.getElementById("form-bulk-items-replacement")

    this.inputQuantityTargets.forEach(element => {
      if (!isNaN(parseInt(element.value))){
        const randomKey = element.id.split("_")[2]
        const destroyElement = document.getElementById(`items_${randomKey}__destroy`)

        if (destroyElement) {
          if (parseInt(destroyElement.value) === 1){
            return
          }
        }

        element.value = parseInt(element.value)
        totalQuantity += parseInt(element.value)
      } else {
        element.value = 0
      }
    })

    if (totalQuantity > parseInt(this.productCountTarget.innerText)){
      this.inputQuantityTargets.forEach(element => {
        element.style.borderColor = "red"
        formBulkItemsReplacement.dataset.performSubmit = "false"
      })
    } else {
      this.inputQuantityTargets.forEach(element => {
        element.style.borderColor = ""
        formBulkItemsReplacement.dataset.performSubmit = "true"
      })
    }
  }

  performSubmit(event) {
    event.preventDefault()

    const formBulkItemsReplacement = document.getElementById("form-bulk-items-replacement")

    if (formBulkItemsReplacement.dataset.performSubmit === "true"){
      const submitButton = document.getElementsByClassName("disbale-on-submit")[0]

      submitButton.disabled = true
      submitButton.classList.add("opacity-25")
      submitButton.value = "Aguarde..."

      formBulkItemsReplacement.requestSubmit()
    } else {
      return
    }
  }
}
