import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.applyErrors()
  }

  applyErrors() {
    const purchaseOrderItemsRows = Array.from(document.getElementsByClassName("quarantine-items"))
    purchaseOrderItemsRows.forEach(purchaseOrderItem => {
      const childrenFields = purchaseOrderItem.querySelectorAll(
        'input[name*="purchase_order[purchase_order_items_attributes]"]' +
        ',select[name*="purchase_order[purchase_order_items_attributes]"]' +
        ',input[id=product_search]'
      )

      childrenFields.forEach(field =>{
        this.addRedBorder(field)
      })
    })
  }

  addRedBorder(field) {
    if(field.value || field.disabled) {
      field.style.borderColor = ""
    } else {
      field.style.borderColor = "red"
    }
  }
}
