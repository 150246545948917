import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["enjoyableItemsButton", "notEnjoyableItemsButton"]

  connect() {
    if (window.location.pathname.indexOf('/not_enjoyable') > -1) {
      this.notEnjoyableItemsButtonTarget.classList.add("bg-slate-700", "text-white")
    } else {
      this.enjoyableItemsButtonTarget.classList.add("bg-slate-700", "text-white")
    }
  }
}
