import { Autocomplete } from "stimulus-autocomplete"

export default class extends Autocomplete {
  static targets = ["input", "hidden", "results"]

  connect() {
    super.connect()
    document.addEventListener("autocomplete.change", this.autocomplete.bind(this))
  }

  clear() {
    if(this.inputTarget.value == "") {
      const index = this.inputTarget.parentNode.dataset.index
      const baseIndex = `purchase_order_purchase_order_items_attributes_${index}`
      document.getElementById(`${baseIndex}_purchased_unit_of_measurement`).value = null
      document.getElementById(`${baseIndex}_received_unit_of_measurement`).value = "und"
    }
  }

  autocomplete(event) {
    const index = this.inputTarget.parentNode.dataset.index
    const baseIndex = `purchase_order_purchase_order_items_attributes_${index}`
    const unitOfMeasurement = event.detail.selected.dataset.autocompleteUnitOfMeasurement
    document.getElementById(`${baseIndex}_purchased_unit_of_measurement`).value = unitOfMeasurement
    document.getElementById(`${baseIndex}_received_unit_of_measurement`).value = unitOfMeasurement
  }
}
