import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize(){
    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success;
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop();

      if (responseSuccess && urlPath === "add_or_replace_items") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("modal-replace-varieties-cancel-button").click()
        }
      }

      if (responseSuccess && urlPath === "bulk_items_replacement") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("modal-bulk-replace-varieties-cancel-button").click()
          document.getElementById("flash_notice_frame").scrollIntoView({ behavior: "smooth", block: "end"});
        }
      }

      if (responseSuccess && urlPath === "bulk_items_replacement_v2") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("modal-bulk-replace-varieties-cancel-button").click()
          document.getElementById("flash_notice_frame").scrollIntoView({ behavior: "smooth", block: "end"});
        }
      }

      if (responseSuccess && urlPath === "bulk_items_replacement_only_to_replace") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("modal-bulk-replace-varieties-cancel-button").click()
          document.getElementById("flash_notice_frame").scrollIntoView({ behavior: "smooth", block: "end"});
        }
      }
    })
  }
}
