import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["outputDiv", "orderPackageId", "formCheckOrderPackage"]

  connect() {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault()

        const split_pattern = "###"
        let orderPackageId = this.outputDivTarget.textContent.trim()

        if(orderPackageId.includes(split_pattern)){
          orderPackageId = orderPackageId.split(split_pattern)[1];
        }

        if (orderPackageId.length === 36) {
          this.orderPackageIdTarget.value = orderPackageId.trim();
          this.formCheckOrderPackageTarget.submit();
        } else {
          this.orderPackageIdTarget.value = ""
          this.outputDivTarget.textContent = "";
          alert("Pedido não identificado! Tente novamente!")
        }
      }

      if (event.key.length === 1) {
        this.outputDivTarget.textContent += event.key;
      }
    });
  }
}
