import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize(){
    this.calculate()
  }

  calculate() {
    const baseFields = "box_composition_box_composition_items_attributes"
    const allBoxCompositionRows = Array.from(document.getElementsByClassName("box-composition-row"))

    let minTotal = 0
    let maxTotal = 0

    allBoxCompositionRows.forEach(boxCompositionRow => {
      if (boxCompositionRow.parentElement.style.display === "none") { return } // Skip deleted rows

      const rowIndex = boxCompositionRow.dataset.index

      this.updateSubtotalElementValue(baseFields, rowIndex, "min_subtotal", "min_quantity")
      this.updateSubtotalElementValue(baseFields, rowIndex, "max_subtotal", "max_quantity")
    })

    minTotal =  this.calculateTotal("item-min-sumarize")
    maxTotal =  this.calculateTotal("item-max-sumarize")

    this.updateTotalElementValue(minTotal, "min-sumarize", "box_composition_min_total")
    this.updateTotalElementValue(maxTotal, "max-sumarize", "box_composition_max_total")
  }

  calculateSubTotal(baseFields, rowIndex, subtotalFieldName) {
    const quantity = parseFloat(document.getElementById(`${baseFields}_${rowIndex}_${subtotalFieldName}`).value.replace(',', '.'))
    const price = parseFloat(document.getElementById(`${baseFields}_${rowIndex}_price`).value.replace(',', '.'))

    if(quantity && price) {
      return quantity * price
    }

    return 0
  }

  updateSubtotalElementValue(baseFields, rowIndex, subtotalFieldName, quantityFieldName) {
    const subtotalElement = document.getElementById(`${baseFields}_${rowIndex}_${subtotalFieldName}`)
    subtotalElement.value = this.calculateSubTotal(baseFields, rowIndex, quantityFieldName)
  }

  calculateTotal(fieldName) {
    const elements = Array.from(document.getElementsByClassName(`${fieldName}`))

    let total = 0

    elements.forEach(element => {
      const row = element.parentElement.parentElement.parentElement

      if (row.style.display === "none") { return } // Skip deleted rows

      if(element.value) {
        total += parseFloat(element.value)
      }
    })

    return total
  }

  updateTotalElementValue(totalValue, sumarizeFieldName, boxCompositionFieldName){
    const fixedValue = totalValue.toFixed(2)
    const formattedTotalValue = Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL" }).format(fixedValue)

    document.getElementById(`${sumarizeFieldName}`).innerHTML = formattedTotalValue
    document.getElementById(`${boxCompositionFieldName}`).value = fixedValue
  }
}
