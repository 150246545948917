import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleItems() {
    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked.purchase-request-checkbox")

    if(this.element.value == "one_pack") {
      this.activateOnePack()
      this.hiddenTwoPacks()
      this.hiddenThreePacks()
    } else if(this.element.value == "two_packs") {
      this.activateTwoPacks()
      this.hiddenThreePacks()
      this.cleanOnePack()
      this.cleanThreePacks()
    } else if(this.element.value == "three_packs") {
      this.activateThreePacks()
      this.hiddenTwoPacks()
      this.cleanOnePack()
      this.cleanTwoPacks()
    }
  }

  hiddenTwoPacks() {
    document.querySelector("#two-packs-section").classList.add("hidden")
  }

  hiddenThreePacks() {
    document.querySelector("#three-packs-section").classList.add("hidden")
  }

  cleanOnePack() {
    const onePackStandard = document.querySelector("#one_pack_standard")
    onePackStandard.value = null
    onePackStandard.disabled = true
  }

  cleanTwoPacks() {
    document.querySelector("#two_packs_without_frozen").checked = false
    document.querySelector("#two_packs_only_frozen").checked = false
  }

  cleanThreePacks() {
    document.querySelector("#three_packs_dry").checked = false
    document.querySelector("#three_packs_refrigerated").checked = false
    document.querySelector("#three_packs_frozen").checked = false
  }

  activateOnePack() {
    const onePackStandard = document.querySelector("#one_pack_standard")
    onePackStandard.value = "one_pack_standard"
    onePackStandard.disabled = false
  }

  activateTwoPacks() {
    document.querySelector("#two-packs-section").classList.remove("hidden")
    document.querySelector("#two_packs_without_frozen").checked = true
    document.querySelector("#two_packs_only_frozen").checked = true
  }

  activateThreePacks() {
    document.querySelector("#three-packs-section").classList.remove("hidden")
    document.querySelector("#three_packs_dry").checked = true
    document.querySelector("#three_packs_refrigerated").checked = true
    document.querySelector("#three_packs_frozen").checked = true
  }
}
