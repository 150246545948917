import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["code"]

  async copy_code() {
    try {
      const toCopy = this.codeTarget.innerHTML
      await navigator.clipboard.writeText(toCopy)
      alert('Código copiado')
    }
    catch {
      alert('Falha ao copiar o código, selecione o código e use CTRL+C.');
    }
  }
}
