import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ["form", "errors", "background", "container"]

  connect() {
    super.connect()
  }

  // initialize() {
  //   document.addEventListener("turbo:submit-start", async (event) => {
  //     const submitButton = document.querySelectorAll("input[type='submit']")[0]
  //     if(submitButton) {
  //       submitButton.disabled = true
  //       submitButton.innerText = "Aguarde..."
  //     }
  //   })
  // }

  handleSuccess({ detail: { success } }) {
    if (success) {
      this.clearErrors()
      window.location.reload(true)
    } else {
      this.element.querySelectorAll("[type=submit]").forEach(e => {
        e.disabled = false
      })
    }
  }

  clearErrors() {
    if (this.hasErrorsTarget) {
      this.errorsTarget.remove()
    }
  }
}
