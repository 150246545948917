import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize(){
    const replaceAllItemsLink = document.getElementById("replace-all-items-link")

    replaceAllItemsLink.addEventListener("click", () => {
      replaceAllItemsLink.disabled = true
      replaceAllItemsLink.classList.add("opacity-25")
    })

    document.addEventListener("turbo:frame-load", async (event) => {
      replaceAllItemsLink.disabled = false
      replaceAllItemsLink.classList.remove("opacity-25")
    })
  }
}
