import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectDeliveryWindow", "formLoadDeliveryWindows", "submitDeliveryWindowsButton" ]

  initialize(){
    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success;
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop();
      if (responseSuccess && urlPath === "destroy") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("flash_notice_frame").scrollIntoView({ behavior: "smooth", block: "start"});
          document.getElementById("modal-cancel-button").click()
        }
      }
    })
  }

  handleClick() {
    const deliveryWindowsUrl = this.formLoadDeliveryWindowsTarget.action.split("/")
    const oldValue = deliveryWindowsUrl[deliveryWindowsUrl.length - 2]
    const newValue = this.selectDeliveryWindowTarget.value
    const newAction = this.formLoadDeliveryWindowsTarget.action.replace(oldValue, newValue)

    this.formLoadDeliveryWindowsTarget.action = newAction
    this.formLoadDeliveryWindowsTarget.requestSubmit()
  }
}
