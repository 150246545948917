import { Controller } from "@hotwired/stimulus";

let interval = null;

export default class extends Controller {
  connect() {
    this.toggleBulkDeleteButton()
  }

  toggleBulkDeleteButton(){
    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked.dependent-order-item-checkbox")
    const bulkDeleteDependentOrderItemsButton = document.querySelector("#bulk-delete-dependent-order-items-button")

    bulkDeleteDependentOrderItemsButton.disabled = true
    bulkDeleteDependentOrderItemsButton.classList.add("opacity-25")

    if (checkedCheckboxes.length > 0) {
      bulkDeleteDependentOrderItemsButton.disabled = false
      bulkDeleteDependentOrderItemsButton.classList.remove("opacity-25")
    }
  }
}

