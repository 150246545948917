import { Controller } from "@hotwired/stimulus";

let interval = null;

export default class extends Controller {
  connect() {
    this.startToSaveAutomatically(120000)
    this.toggleBulkGenerationButton()
  }

  startToSaveAutomatically(timeInMilliseconds) {
    if (interval) clearInterval(interval)

    interval = setInterval(() => {
      this.submitCart();
    }, timeInMilliseconds)
  }

  submitHeader() {
    document.getElementById("form-purchase-request-header").requestSubmit()
  }

  submitCart() {
    document.getElementById("form-purchase-request-cart").requestSubmit()
  }

  toggleBulkGenerationButton(){
    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked.purchase-request-checkbox")
    const bulkGeneratePurchaseOrdersButton = document.querySelector("#bulk-generate-purchase-orders-button")

    bulkGeneratePurchaseOrdersButton.disabled = true
    bulkGeneratePurchaseOrdersButton.classList.add("opacity-25")

    if (checkedCheckboxes.length > 0) {
      bulkGeneratePurchaseOrdersButton.disabled = false
      bulkGeneratePurchaseOrdersButton.classList.remove("opacity-25")
    }
  }
}

