// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
// import Rails from '@rails/ujs'
// import Turbolinks from 'turbolinks'
// import "@hotwired/turbo-rails"
import '@rails/ujs'
import Alpine from 'alpinejs'

// Import all channels.
// import.meta.globEager('./**/*_channel.js')

// Rails.start()
// Turbolinks.start()

window.Alpine = Alpine
Alpine.start()

import '~/controllers/index.js'

import 'virtual:windi.css'
//import 'virtual:windi-devtools'
import 'virtual:windi-components.css'
import 'virtual:windi-utilities.css'

import '~/stylesheets/application.scss'

import 'chartkick/chart.js'
import 'chartjs-chart-funnel'
import 'chartkick/highcharts'
import 'highcharts/modules/accessibility'
import 'highcharts/modules/exporting'
import 'highcharts/modules/export-data'
