import IMask from 'imask';
import { Controller } from "stimulus"

export default class extends Controller {

  // iMask Documentation:
  // https://imask.js.org/guide.html#getting-started

  connect() {
    const maskOptions = {
      mask: this.element.getAttribute("imask_mask") ?? "000.000.000-00",
      lazy: false,
    }

    IMask(this.element, maskOptions)
  }
}
