import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["promoKind", "promoKindShow", "scheduleShipping", "scheduledShippingShow"]

  connect() {
    this.togglePromoKind()
    this.toggleScheduleShipping()
  }

  retrieveProductPrice() {
    const retrieveProductPriceButton = document.querySelector("#retrieve-product-price-button")
    const productId = document.querySelector("[name='[promotion]promo_product_id'").value
    const rootUrl = retrieveProductPriceButton.form.action.split("?")[0]
    const newAction = `${rootUrl}?id=${productId}`

    retrieveProductPriceButton.form.action = newAction
    retrieveProductPriceButton.form.requestSubmit()
  }

  toggleScheduleShipping() {
    this.hideSection("by_list_import", "schedule")
    this.hideSection("by_box_composition", "schedule")
    this.hideSection("by_subscription_classification", "schedule")
    this.hideSection("test_shipping", "schedule")

    if (this.hasScheduleShippingTarget) {
      this.showSection(this.scheduleShippingTarget.value, "schedule")
    }

    if (this.hasScheduledShippingShowTarget) {
      this.showSection(this.scheduledShippingShowTarget.dataset.scheduleShipping, "schedule")
    }
  }

  togglePromoKind() {
    this.hideSection("buy_x_units_get_y_units", "promo")
    this.hideSection("discount_offer", "promo")
    this.hideSection("upgrade_product", "promo")
    this.hideSection("buy_product_x_get_product_y", "promo")
    this.hideSection("buy_2_units_get_x_percent_off", "promo")

    if (this.hasPromoKindTarget) {
      this.showSection(this.promoKindTarget.value, "promo")
    }

    if (this.hasPromoKindShowTarget) {
      this.showSection(this.promoKindShowTarget.dataset.promoKind, "promo")
    }
  }

  hideSection(section, suffix) {
    document.querySelectorAll(`.${suffix}_${section}_section`).forEach(e => {
      e.style.display = "none"
    })
  }

  showSection(section, suffix) {
    document.querySelectorAll(`.${suffix}_${section}_section`).forEach(e => {
      e.style.display = ""
    })
  }
}
