import { Controller } from "@hotwired/stimulus"

let checkedIds = []
const checkboxes = document.getElementsByName("order_item_id");

export default class extends Controller {
  static targets = [ "dids", "sids", "iids", "ids", "submit", "selectAllButton" ]

  connect() {
    this.selectAllButtonTarget.addEventListener('click', () => {
      this.toggleSelectAll()
    })

    const context = this

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        context.setCheckedIds()
      })
    })
  }

  setCheckedIds() {
    checkedIds =
      Array.from(checkboxes)
      .filter(i => i.checked)
      .map(i => i.value)

    this.idsTarget.value = checkedIds

    if(this.idsTarget) {
      if (this.hasDidsTarget) this.didsTarget.value = checkedIds
      if (this.hasIidsTarget) this.iidsTarget.value = checkedIds
    }
  }

  toggleSelectAll() {
    if (this.idsTarget.value == "") {
      for (let checkbox of checkboxes) {
        checkbox.checked = true
        this.setCheckedIds()
      }
    } else {
      for (let checkbox of checkboxes) {
        checkbox.checked = false
        this.setCheckedIds()
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.idsTarget.value == "") {
      alert("É necessário selecionar ao menos um pedido")
    } else {
      event.target.closest("form").submit();
    }
  }
}
