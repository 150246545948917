import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "supplierId", "deliveryDate", "productId", "purchaseOrderId", "submitButton" ]

  initialize(){
    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop()

      if (responseSuccess && urlPath === "print_extra_label") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          window.open(headers.get("X-Mercado-Redirect-Url"), "_blank")
        } else {
          window.location.reload(true)
        }
      }
    })
  }

  handleLoadPurchaseOrderIds() {
    const formloadPurchaseOrderIds = document.querySelector("#form_load_purchase_order_ids")

    if (this.productIdTarget.value == "" || this.deliveryDateTarget.value == "" || this.supplierIdTarget.value == "") {
      return
    }

    formloadPurchaseOrderIds.querySelector("#product_id").value = this.productIdTarget.value
    formloadPurchaseOrderIds.querySelector("#delivery_date").value = this.deliveryDateTarget.value
    formloadPurchaseOrderIds.querySelector("#supplier_id").value = this.supplierIdTarget.value

    formloadPurchaseOrderIds.requestSubmit()
  }

  handleLoadInventoryBoxPortion() {
    const formLoadInventoryBoxPortion = document.querySelector("#form_load_inventory_box_portion")

    if (this.productIdTarget.value == "" || this.purchaseOrderIdTarget.value == "") {
      return
    }

    formLoadInventoryBoxPortion.querySelector("#product_id").value = this.productIdTarget.value
    formLoadInventoryBoxPortion.querySelector("#purchase_order_id").value = this.purchaseOrderIdTarget.value

    formLoadInventoryBoxPortion.requestSubmit()
  }
}
