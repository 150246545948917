// import { Application } from "stimulus"
import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"
import { registerControllers } from "stimulus-vite-helpers"
import { Dropdown, Modal, Tabs } from "tailwindcss-stimulus-components"
import { Autocomplete } from 'stimulus-autocomplete'
import Chart from "chart.js/auto"
import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel"

// Start the Stimulus application.
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

// Controller files must be named *_controller.js.
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })
registerControllers(application, controllers)

application.register('autocomplete', Autocomplete)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('chart', Chart)

Chart.register(
  FunnelController,
  TrapezoidElement,
);
