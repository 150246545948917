import Flatpickr from 'stimulus-flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import "flatpickr/dist/flatpickr.css"

export default class extends Flatpickr {
  static targets = ["requestAt", "receivedAt"]

  initialize() {
    this.config = {
      locale: Portuguese,
      dateFormat: 'd/m/Y',
      prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }
  }

  ready(selectedDates, dateStr, instance) {
    if(instance.config.mode == "range") {
      if(dateStr.includes('até')) {
        instance.element.value = dateStr.replace('até', '-')
      } else {
        instance.element.value = `${dateStr} - ${dateStr}`
      }
    }
    const customClass = instance.element.getAttribute('data-class')
    instance.calendarContainer.classList.add(customClass)
  }

  change(selectedDates, dateStr, instance) {
    if(instance.config.mode == "range") {
      instance.element.value = dateStr.replace('até', '-')
    }

    if(instance.element.dataset.purchase) {
      this.loadPurchaseRequestDates(instance.element)
    }
  }

  loadPurchaseRequestDates(element) {
    const productId = element.parentNode.parentNode.dataset.productId
    const fieldName = element.name.split("_")[1]
    const fieldsToUpdate = Array.from(document.getElementsByClassName(`${productId}-${fieldName}`))

    fieldsToUpdate.forEach(field => {
      field.value = element.value
    })
  }
}
