import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modalButton", "modalButtonText", "saveButton",
                    "availableQuantity", "attendedPortion"]

  initialize(){
    document.addEventListener("turbo:frame-render", (event) => {
      this.enableButton()
    })

    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success;
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop();
      if (responseSuccess && urlPath === "bulk_planning_personalization_items") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("flash_notice_frame").scrollIntoView({ behavior: "smooth", block: "start"});
          document.getElementById("modal-cancel-button").click()
        }
      }
    })
  }

  disableSaveButton(){
    this.saveButtonTarget.classList.remove("bg-indigo-500", "hover:bg-indigo-600")
    this.saveButtonTarget.classList.add("bg-indigo-300")
    this.saveButtonTarget.value = "Aguarde..."
  }

  disableButton(event){
    if (this.hasModalButtonTarget){
      if (this.modalButtonTarget.disabled === true) {
        event.preventDefault()
      }

      this.modalButtonTarget.disabled = true
      this.modalButtonTarget.classList.remove("bg-indigo-500", "hover:bg-indigo-600")
      this.modalButtonTarget.classList.add("bg-indigo-300")
      this.modalButtonTextTarget.innerText = "Aguarde..."
    }
  }

  enableButton(){
    if (this.hasModalButtonTarget){
      this.modalButtonTarget.disabled = false
      this.modalButtonTarget.classList.add("bg-indigo-500", "hover:bg-indigo-600")
      this.modalButtonTarget.classList.remove("bg-indigo-300")
      this.modalButtonTextTarget.innerText = "Personalização"
    }
  }

  calculatePortions(){
    const availableQuantity = parseFloat(this.availableQuantityTarget.value, 10)
    const maxQuantity = parseFloat(this.attendedPortionTarget.dataset.maxQuantity, 10)

    this.attendedPortionTarget.innerText = (availableQuantity / maxQuantity).toFixed(2)
  }
}
