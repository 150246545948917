import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkBoxSelect" ]

  toggleSelectAll() {
    this.checkBoxSelectTargets.forEach(element => {
      element.checked = !element.checked
    })
  }
}
