import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "subReasons",
                     "reasonSelect",
                     "subReasonSelect",
                     "solutionSelect",
                     "partialRefundSection",
                     "partialRefundSelect",
                     "valueRefundLabel",
                     "deliveryFeeLabel",
                     "valueRefundInput",
                     "refundValueDiv",
                     "productSelect",
                     "productSelectDiv" ]

  connect() {
    this.subReasonsDataValue = JSON.parse(this.subReasonsTarget.dataset.reasons)["pt-BR"].sub_reasons
    this.updateReasonsOptions()
    this.verifyPartialRefund()
    this.verifyPartialRefundType()
  }

  updateReasonsOptions() {
    let last_value = this.subReasonSelectTarget.value
    this.subReasonSelectTarget.innerHTML = ""

    const currentSubReasons = this.subReasonsDataValue[this.reasonSelectTarget.value]

    for (let key in currentSubReasons) {
      let option = document.createElement("option");
      option.value = key
      option.text = currentSubReasons[key]
      if(last_value == key){
        option.selected = true
      }
      this.subReasonSelectTarget.add(option)
    }
  }

  verifyPartialRefund() {
    if(this.solutionSelectTarget.value == "partial_chargeback"){
      this.partialRefundSectionTarget.style.display = "flex"
    } else {
      this.partialRefundSectionTarget.style.display = "none"
      this.partialRefundSelectTarget.value = ""
      this.valueRefundLabelTarget.innerHTML = "Esperando preenchimento do tipo de estorno parcial"
    }
  }

  verifyPartialRefundType() {
    const partialRefundType = this.partialRefundSelectTarget.value
    switch(partialRefundType) {
      case "independent_refund":
        this.handleIndependentRefund()
        this.handleClearSelect()
        break
      case "applied_in_delivery_fee":
        this.handleAppliedInDeliveryFee()
        this.handleClearSelect()
        break
      case "applied_in_one_or_more_items":
        this.handleAppliedInOneOrMoreItems()
        break
      default:
        this.handleDefaultCase()
        this.handleClearSelect()
    }
  }

  handleIndependentRefund() {
    this.refundValueDivTarget.style.display = "block"
    this.valueRefundLabelTarget.innerHTML = "Valor a ser estornado"
    this.valueRefundInputTarget.readOnly = false
    this.valueRefundInputTarget.value = ""
    this.valueRefundInputTarget.style.display = "block"
    this.valueRefundInputTarget.type = "number"
    this.productSelectDivTarget.style.display = "none"
    this.deliveryFeeLabelTarget.style.display = "none"
  }

  handleAppliedInDeliveryFee() {
    this.refundValueDivTarget.style.display = "block"
    this.valueRefundLabelTarget.innerHTML = "Valor a ser estornado"
    this.valueRefundInputTarget.type = "hidden"
    this.valueRefundInputTarget.value = parseFloat(this.valueRefundInputTarget.dataset.deliveryFee).toFixed(2)
    this.deliveryFeeLabelTarget.style.display = "block"
    this.deliveryFeeLabelTarget.innerHTML = `R$ ${parseFloat(this.valueRefundInputTarget.dataset.deliveryFee).toFixed(2)}`
    this.valueRefundInputTarget.style.display = "block"
    this.productSelectDivTarget.style.display = "none"
  }

  handleAppliedInOneOrMoreItems() {
    this.refundValueDivTarget.style.display = "block"
    this.valueRefundLabelTarget.innerHTML = "Itens"
    this.valueRefundInputTarget.style.display = "none"
    this.valueRefundInputTarget.value = ""
    this.productSelectDivTarget.style.display = "block"
    this.deliveryFeeLabelTarget.style.display = "none"
  }

  handleDefaultCase() {
    this.valueRefundLabelTarget.innerHTML = "Esperando preenchimento do tipo de estorno parcial"
    this.refundValueDivTarget.style.display = "flex"
    this.valueRefundInputTarget.style.display = "none"
    this.productSelectDivTarget.style.display = "none"
    this.deliveryFeeLabelTarget.style.display = "none"
  }

  handleClearSelect() {
    const selectedOptions = this.productSelectTarget.selectedOptions
    if (selectedOptions.length > 0) {
      this.productSelectTarget.tomselect.clear()
    }
  }
}
