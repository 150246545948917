import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectReceivedUnitOfMeasurement", "inputMultFactor"]

  initialize() {
    this.change();
  }

  change() {
    if (this.hasSelectReceivedUnitOfMeasurementTarget) {
      if (this.selectReceivedUnitOfMeasurementTarget.value === "und"){
        this.enableInputMultFactor()
      } else {
        this.disableInputMultFactor()
      }
    }
  }

  enableInputMultFactor() {
    if (this.hasInputMultFactorTarget) {
      this.inputMultFactorTarget.disabled = false
      this.inputMultFactorTarget.classList.remove("opacity-25")
    }
  }

  disableInputMultFactor() {
    if (this.hasInputMultFactorTarget) {
      this.inputMultFactorTarget.disabled = true
      this.inputMultFactorTarget.classList.add("opacity-25")
    }
  }
}
