import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkBox" ]

  bulkDelete(event) {
    event.preventDefault()

    if (confirm("Deseja realmente apagar?") == false) {
      return
    }

    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked")

    if (checkedCheckboxes.length > 0) {
      let ids = []

      checkedCheckboxes.forEach(element => {
        ids.push(element.value)
      })

      let operatorIdsElm = document.querySelector("[name^=operator_ids]")

      operatorIdsElm.value = ids.join(",")

      operatorIdsElm.closest("form").requestSubmit()
    }
  }

  toggleSelectAll() {
    const checkBoxSelector = document.querySelector("#checkbox-selector")

    checkBoxSelector.checked = !checkBoxSelector.checked

    document.querySelectorAll("[name^=operators_selected_ids]").forEach(element => {
      element.checked = checkBoxSelector.checked
    })

    this.checkCheckBoxSelected()
  }

  checkCheckBoxSelected() {
    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked")
    const bulkDeleteButton = document.querySelector("#bulk-delete-button")

    bulkDeleteButton.disabled = true
    bulkDeleteButton.classList.add("opacity-25")

    if (checkedCheckboxes.length > 0) {
      bulkDeleteButton.disabled = false
      bulkDeleteButton.classList.remove("opacity-25")
    }
  }
}
