import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize(){
    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop()

      if (responseSuccess && urlPath === "print_updated_labels") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          window.open(headers.get("X-Mercado-Redirect-Url"), "_blank")
        } else {
          window.location.reload(true)
        }
      }
    })
  }
}