import { Autocomplete } from "stimulus-autocomplete"

export default class extends Autocomplete {
  static targets = ["input", "hidden", "results"]

  connect() {
    super.connect()
    document.addEventListener("autocomplete.change", this.autocomplete.bind(this))
  }

  clear() {
    if(this.inputTarget.value == "") {
      const baseIndex = this.inputTarget.parentNode.dataset.index
      document.getElementById(`${baseIndex}-max-quantity`).innerText = ""
      document.getElementById(`${baseIndex}-total`).innerText = ""
    }
  }

  autocomplete(event) {
    const baseIndex = event.target.dataset.index
    const maxQuantity = event.detail.selected.dataset.autocompleteMaxQuantity
    const unitOfMeasurement = event.detail.selected.dataset.autocompleteUnitOfMeasurement
    const currentPortions = parseFloat(document.getElementById(`${baseIndex}-current-portions`).innerText)
    const totalPortions = (currentPortions * maxQuantity).toFixed(2)

    document.getElementById(`${baseIndex}-max-quantity`).innerText = `${maxQuantity} (${unitOfMeasurement})`
    document.getElementById(`${baseIndex}-total`).innerText = `${totalPortions} (${unitOfMeasurement})`
  }
}
