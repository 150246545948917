import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleButton", "textArea"]

  initialize() {
    this.toggle();
  }

  toggle() {
    this.textAreaTarget.disabled =  !this.toggleButtonTarget.checked;
  }
}
