import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.checkFinishWorkstationButton()
  }

  toggleCheckItem() {
    const itemKindElm = this.element.dataset.itemKind
    const imgElm = this.element.querySelector("img")
    const imgSrc = imgElm.src
    const finishOrderAssemblyButton = document.querySelector("#finish-order-assembly")
    const workstationButton = document.querySelector("#finish-workstation-button")
    const countElm = document.querySelector(`#order_assembly_item_${itemKindElm}_count`)
    const countElmValue = parseInt(countElm.innerText.trim(), 10)

    if (finishOrderAssemblyButton.dataset.orderAssemblyFinished === "false") {
      workstationButton.dataset.finishedWorkstation = false
      this.checkFinishWorkstationButton()

      if (imgSrc.indexOf("icons/check-green") !== -1) {
        this.setButtonToGray(imgElm, countElm, countElmValue)
      } else {
        this.setButtonToGreen(imgElm, countElm, countElmValue)
      }
    }
  }

  setButtonToGray(imgElm, countElm, countElmValue) {
    imgElm.src = document.getElementById("checkGrayImage").src
    imgElm.classList.add("opacity-25")
    countElm.innerText = countElmValue - 1
    this.element.querySelector("[name$=checked]").value = false
  }

  setButtonToGreen(imgElm, countElm, countElmValue) {
    imgElm.src = document.getElementById("checkGreenImage").src
    imgElm.classList.remove("opacity-25")
    countElm.innerText = countElmValue + 1
    this.element.querySelector("[name$=checked]").value = true
  }

  checkFinishWorkstationButton() {
    const workstationButton = document.querySelector("#finish-workstation-button")
    if (workstationButton.dataset.finishedWorkstation === "true") {
      workstationButton.classList.remove("bg-indigo-500", "hover:bg-indigo-600")
      workstationButton.classList.add("bg-green-500", "hover:bg-green-600")
      workstationButton.text = "Ilha finalizada"
    } else {
      workstationButton.classList.remove("bg-green-500", "hover:bg-green-600")
      workstationButton.classList.add("bg-indigo-500", "hover:bg-indigo-600")
      workstationButton.text = "Finalizar ilha"
    }
  }
}
