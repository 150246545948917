import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  applyPurchasedPriceToAll() {
    const inputs = Array.from(document.getElementsByClassName("input-received-quantity"))
    inputs.forEach(element => {
      if (!element.disabled){
        element.value = element.dataset.purchasedQuantity
      }
    })
  }

  applyReceivedToAll() {
    const selects = Array.from(document.getElementsByClassName("select-status"))
    selects.forEach(element => {
      if (!element.disabled) {
        element.value = "received"
      }
    })
  }
}
