import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "deliveryDate"]

  handleChangeBaselineDate() {
    const formLoadDeliveryPeriodsPerDay = document.querySelector("#form_load_delivery_periods_per_day")
    const deliveryWindowsUrl = formLoadDeliveryPeriodsPerDay.action.split("=")
    const oldValue = deliveryWindowsUrl[1]
    const newValue = encodeURIComponent(this.deliveryDateTarget.value)
    const newAction = formLoadDeliveryPeriodsPerDay.action.replace(oldValue, newValue)

    formLoadDeliveryPeriodsPerDay.action = newAction
    formLoadDeliveryPeriodsPerDay.requestSubmit()
  }
}
