import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "subReasons", "reasonSelect", "subReasonSelect" ]
  static values = [ "subReasonsData" ]

  connect() {
    this.subReasonsDataValue = JSON.parse(this.subReasonsTarget.dataset.cancel_reasons)["pt-BR"].cancel_reasons
    
    this.updateReasonsOptions()
  }

  updateReasonsOptions() {
    this.subReasonSelectTarget.innerHTML = ""

    const currentSubReasons = this.subReasonsDataValue[this.reasonSelectTarget.value]

    for (let key in currentSubReasons) {
      let option = document.createElement("option"); 
      option.value = key
      option.text = currentSubReasons[key]
      this.subReasonSelectTarget.add(option)
    }
  }
}