import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const permissions = document.querySelectorAll("li > input[type=checkbox]")

    permissions.forEach(item => {
      if(item.value == "true") {
        item.setAttribute("checked", "checked")
      } else {
        item.removeAttribute("checked")
      }
    })

    permissions.forEach(item => {
      item.addEventListener('change', () => {
        item.value = item.checked ? "true" : "false"
        item.closest('li').querySelector('input[type=hidden]').value = item.value
      })
    })
  }
}
