import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkBoxSelectAll", "checkBox", "generateLabelButton" ]

  initialize() {
    this.checkBoxClick()
  }

  toggleSelectAll() {
    this.checkBoxTargets.forEach(element => {
      element.checked = this.checkBoxSelectAllTarget.checked
    })

    this.checkBoxClick()
  }

  checkBoxClick(event) {
    let checkedBoxes = this.checkBoxTargets.filter(element => {
      return element.checked === true
    })

    if (checkedBoxes.length > 0){
      let purchaseOrderItemIds = []

      checkedBoxes.forEach(checkBoxElement => {
        purchaseOrderItemIds.push(checkBoxElement.value)
      })

      this.generateLabelButtonTargets.forEach(generateLabelButtonElement => {
        let href = generateLabelButtonElement.dataset.link

        generateLabelButtonElement.classList.remove("opacity-75")
        generateLabelButtonElement.classList.remove("cursor-default")
        generateLabelButtonElement.classList.add("hover:bg-indigo-600")
        generateLabelButtonElement.disabled = false
        generateLabelButtonElement.readonly = false

        generateLabelButtonElement.setAttribute("href", `${href}?purchase_order_item_ids=${purchaseOrderItemIds.join(",")}`)
      })
    } else {
      this.generateLabelButtonTargets.forEach(element => {
        element.setAttribute("href", "#")
        element.classList.add("opacity-75")
        element.classList.add("cursor-default")
        element.classList.remove("hover:bg-indigo-600")
        element.disabled = true
        element.readonly = true
      })
    }
  }

  generateLabelButtonClick(event) {
    if (this.generateLabelButtonTargets[0].disabled === true){
      event.preventDefault()
    }
  }
}
