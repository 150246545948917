import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkBoxSelectAll", "checkBox", "actionButton" ]

  initialize() {
    this.checkBoxClick()
  }

  toggleSelectAll() {
    this.checkBoxTargets.forEach(element => {
      element.checked = this.checkBoxSelectAllTarget.checked
    })

    this.checkBoxClick()
  }

  checkBoxClick() {
    let checkedBoxes = this.checkBoxTargets.filter(element => {
      return element.checked === true
    })

    if (checkedBoxes.length > 0) {
      let purchaseOrderItemIds = []

      checkedBoxes.forEach(checkBoxElement => {
        purchaseOrderItemIds.push(checkBoxElement.value)
      })

      this.actionButtonTargets.forEach(actionButtonElement => {
        let href = actionButtonElement.dataset.link
        let action = actionButtonElement.dataset.value
        let linkParams = actionButtonElement.dataset.linkParams

        actionButtonElement.classList.remove("opacity-75")
        actionButtonElement.classList.remove("cursor-default")
        if(action == "accepted") {
          actionButtonElement.classList.add("hover:bg-indigo-600")
        } else {
          actionButtonElement.classList.add("hover:bg-rose-600")
          actionButtonElement.classList.add("hover:text-white")
        }
        actionButtonElement.disabled = false
        actionButtonElement.readonly = false

        actionButtonElement.setAttribute("href", `${href}&${linkParams}&purchase_order_item_ids=${purchaseOrderItemIds.join(",")}`)
      })
    } else {
      this.actionButtonTargets.forEach(element => {
        let action = element.dataset.value
        element.setAttribute("href", "#")
        element.classList.add("opacity-75")
        element.classList.add("cursor-default")
        if(action == "accepted") {
          element.classList.remove("hover:bg-indigo-600")
        } else {
          element.classList.remove("hover:bg-rose-600")
        }

        element.disabled = true
        element.readonly = true
      })
    }
  }

  actionButtonClick(event) {
    if (this.actionButtonTargets[0].disabled === true) {
      event.preventDefault()
    }
  }
}
