import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit(event) {
    event.preventDefault()
    const workstationButton = document.querySelector("#finish-workstation-button")
    if (workstationButton.dataset.finishedWorkstation === "false") {
      document.querySelector("#form-check-item").requestSubmit()
    }
  }
}
