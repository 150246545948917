import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.adjustModalWindowSize()
  }

  initialize(){
    this.calculate()
  }

  calculate(){
    const purchaseOrderItemsRows = Array.from(document.getElementsByClassName("purchase-order-items-rows"))
    const remainingArr = []
    const boxQuantityArr = []
    const quantityItemsPerBoxArr = []

    purchaseOrderItemsRows.forEach(purchaseOrderItem => {
      let totalDistributed = 0
      let totalRemaining = 0

      const purchaseOrderItemReceivementsRows = Array.from(purchaseOrderItem.getElementsByClassName("purchase-order-item-receivements-rows"))
      const receivedQuantity = purchaseOrderItem.querySelector(".purchased-order-item-received-quantity").dataset.receivedQuantity
      const totalDistributedElm = purchaseOrderItem.querySelector(".total-distributed")
      const totalRemainingElm = purchaseOrderItem.querySelector(".total-remaining")
      const totalRemainingWrapperElm = purchaseOrderItem.querySelector(".total-remaining-wrapper")

      purchaseOrderItemReceivementsRows.forEach(purchaseOrderItemReceivementRows => {
        const purchaseOrderItemReceivementRow = Array.from(purchaseOrderItemReceivementRows.getElementsByClassName("purchase-order-item-receivement-row"))

        purchaseOrderItemReceivementRow.forEach(elementsInARow =>{
          const boxQuantityElm = elementsInARow.querySelector("input[name$='[box_quantity]']")
          const quantityItemsPerBoxElm = elementsInARow.querySelector("input[name$='[quantity_items_per_box]']")
          const destroyInputValue = elementsInARow.querySelector("input[name*='_destroy']").value

          this.addRedBorder([boxQuantityElm, quantityItemsPerBoxElm])

          boxQuantityArr.push(boxQuantityElm)
          quantityItemsPerBoxArr.push(quantityItemsPerBoxElm)

          if (destroyInputValue === "1") {
            totalDistributed += 0
          } else {
            totalDistributed += ((parseFloat(boxQuantityElm.value, 10) || 0) * (parseFloat(quantityItemsPerBoxElm.value, 10) || 0))
          }
        })

        totalRemaining = receivedQuantity - totalDistributed

        if (totalRemaining > 0){
          totalRemainingWrapperElm.classList.add("bg-rose-100")
          totalRemainingWrapperElm.classList.remove("bg-green-100")
          totalRemainingWrapperElm.classList.remove("bg-yellow-100")
        } else if (totalRemaining < 0) {
          totalRemainingWrapperElm.classList.remove("bg-rose-100")
          totalRemainingWrapperElm.classList.remove("bg-green-100")
          totalRemainingWrapperElm.classList.add("bg-yellow-100")
        } else {
          totalRemainingWrapperElm.classList.remove("bg-rose-100")
          totalRemainingWrapperElm.classList.add("bg-green-100")
          totalRemainingWrapperElm.classList.remove("bg-yellow-100")
        }
      })

      totalDistributedElm.innerHTML = totalDistributed
      totalRemainingElm.innerHTML = totalRemaining
      remainingArr.push(totalRemaining)
    })

    return {
      remainingItems: remainingArr.reduce((accumulator, currentValue) => accumulator + currentValue) !== 0,
      invalidBoxQuantityElm: boxQuantityArr.find(element => ((parseFloat(element.value, 10) || 0) > 999) || ((parseFloat(element.value, 10) || 0) < 1)),
      invalidQuantityItemsPerBoxElm: quantityItemsPerBoxArr.find(element => ((parseFloat(element.value, 10) || 0) < 1))
    }
  }

  addRedBorder(elements){
    elements.forEach(element => {
      if (
          (element.value === "") ||
          (element.name.endsWith("[quantity_items_per_box]") && ( (parseFloat(element.value, 10) < 1) ) ) ||
          (element.name.endsWith("[box_quantity]") && (parseFloat(element.value, 10) < 1))
        ){
        element.style.borderColor = "red"
        return
      }

      element.style.borderColor = ""
    })
  }

  submit(event){
    event.preventDefault()
    const { remainingItems, invalidBoxQuantityElm, invalidQuantityItemsPerBoxElm } = this.calculate()

    if (invalidBoxQuantityElm !== undefined) {
      invalidBoxQuantityElm.style.borderColor = "red"
      this.showErrorMessage("É necessário preencher a quantidade de caixas corretamente!")
      return
    }

    if (invalidQuantityItemsPerBoxElm !== undefined) {
      invalidQuantityItemsPerBoxElm.style.borderColor = "red"
      this.showErrorMessage("É necessário preencher a quantidade de itens por caixa corretamente!")
      return
    }

    if (remainingItems) {
      this.showErrorMessage("É necessário distribuir todos os items antes de enviar!")
      return
    }

    const puchaseOrderItemReceivementsForm = document.getElementById("purchase-order-item-receivements-form")
    puchaseOrderItemReceivementsForm.requestSubmit()
  }

  showErrorMessage(message){
    const htmlMessage = `
      <div data-controller="flash" class="px-4 py-2 rounded-sm text-sm border border-rose-200 text-rose-600 bg-rose-100" role="alert">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"></path>
            </svg>
            <div>${message}</div>
          </div>
          <button class="opacity-70 hover:opacity-80 ml-3 mt-[3px]" data-action="flash#dismiss">
            <div class="sr-only">Close</div>
            <svg class="w-4 h-4 fill-current">
              <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"></path>
            </svg>
          </button>
        </div>
      </div>
    `

    document.getElementById("modal-error-messages").innerHTML = htmlMessage
  }

  adjustModalWindowSize(){
    const dynamicModalWindow = document.getElementById("dynamic-modal-window")

    if (dynamicModalWindow) {
      const dynamicModalWindowHeight = dynamicModalWindow.offsetHeight
      const dynamicModalWindowWidth = dynamicModalWindow.offsetWidth
      const dynamicModalWindowHeaderHeight = document.getElementById("dynamic-modal-window-header").offsetHeight
      const dynamicModalWindowSupplierHeader = document.getElementById("dynamic-supplier-header").offsetHeight
      const dynamicModalWindowFooterHeight = document.getElementById("dynamic-modal-window-footer").offsetHeight
      const dynamicModalWindowMessage = document.getElementById("dynamic-modal-window-message")
      const finalSize = Math.ceil(dynamicModalWindowHeight - (dynamicModalWindowHeaderHeight + dynamicModalWindowFooterHeight + dynamicModalWindowSupplierHeader))
      const dynamicScrollArea = document.getElementById("dynamic-scroll-area")

      if (dynamicScrollArea){
        dynamicScrollArea.className = (`h-${finalSize}px max-h-${finalSize}px overflow-y-scroll`)
        dynamicScrollArea.style.maxHeight = `${finalSize}px`
        dynamicScrollArea.style.height = `${finalSize}px`
      }

      if (dynamicModalWindowMessage){
        dynamicModalWindowMessage.style.top = `${(Math.ceil(dynamicModalWindowHeight / 3) * 2) - 10}px`
        dynamicModalWindowMessage.style.right = `${Math.ceil(dynamicModalWindowWidth / 2) - (dynamicModalWindowMessage.offsetWidth / 2)}px`
      }
    }
  }
}
