import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["discountPercentage", "kindSelect", "saleImage"]

  initialize() {
    this.selectChanged();
  }

  selectChanged() {
    if (this.hasKindSelectTarget && this.hasDiscountPercentageTarget && this.hasSaleImageTarget) {
      if (this.kindSelectTarget.value === "combo"){
        this.discountPercentageTarget.disabled = false
        this.discountPercentageTarget.classList.remove("hidden")
        this.saleImageTarget.classList.remove("hidden")
      } else {
        this.discountPercentageTarget.disabled = true
        this.discountPercentageTarget.classList.add("hidden")
        this.saleImageTarget.classList.add("hidden")
      }
    }
  }
}
