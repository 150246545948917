import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  prev() {
    const currentIndex = parseInt(this.element.dataset.index)
    const prevIndex = currentIndex - 1

    document.getElementById(`table-data-${currentIndex}`).classList.add("hidden")
    document.getElementById(`table-data-${prevIndex}`).classList.remove("hidden")
  }

  next() {
    const currentIndex = parseInt(this.element.dataset.index)
    const nextIndex = currentIndex + 1

    document.getElementById(`table-data-${currentIndex}`).classList.add("hidden")
    document.getElementById(`table-data-${nextIndex}`).classList.remove("hidden")
  }
}
