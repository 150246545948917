import { Controller } from "@hotwired/stimulus"
const mapDivs =  Array.from(document.getElementsByClassName("map_canvas"))
const pacInput = document.getElementById('pac-input');

export default class extends Controller {
  static targets = ["map", "loadButton"]
  static values = { coordinates: String }

  initialize() {
      // Hide map wrappers
      mapDivs.forEach((mapDiv)=>{
        mapDiv.classList.add("hidden")
        mapDiv.classList.remove("block")
      })

      if(pacInput) {
        pacInput.classList.add("hidden")
      }

    // Keeping this, maybe it's going to be used in the future
    // if (typeof (google) != "undefined"){
      // this.initMap()
    // }
  }

  coordinates() {
    return JSON.parse(this.mapTarget.dataset.mapsCoordinatesValue)
  }

  setupPage() {
    this.loadButtonTarget.textContent = "Carregando mapa..."
    const context = this

    setTimeout(() => {
      this.loadButtonTarget.style.display = "none"

      // Show map wrappers
      mapDivs.forEach((mapDiv)=>{
        mapDiv.classList.remove("hidden")
        mapDiv.classList.add("block")
      })

      if(pacInput) {
        pacInput.classList.remove("hidden")
      }

      context.initMap()
    }, 1000);
  }

  initMap() {
    if(this._map == undefined) {
      let trueTypeOf = (obj) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
      let centerMapLatLng
      let zoomMap
      const bounds = new google.maps.LatLngBounds()

      // Add polygons coordinates to the bounds
      if (trueTypeOf(this.coordinates()[0]) == "object"){ // Only one polygon
        for (let i = 0; i < this.coordinates().length; i++) {
          bounds.extend(this.coordinates()[i])
        }

        zoomMap = 12
        centerMapLatLng = bounds.getCenter()

      } else { // Multi polygons
        for (let i = 0; i < this.coordinates().length; i++) {
          for (let j = 0; j < this.coordinates()[i]; j++) {
            bounds.extend(this.coordinates()[i][j]);
          }
        }

        zoomMap = 10
        centerMapLatLng = new google.maps.LatLng(-23.533773, -46.625290) // São Paulo
      }

      this._map = new google.maps.Map(this.mapTarget, {
        zoom: zoomMap,
        mapTypeId: "terrain",
        center: centerMapLatLng
      })

      this._polygonArea = new google.maps.Polygon({
        paths: this.coordinates(),
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.25,
      })

      this._polygonArea.setMap(this._map)

      if(pacInput) {
        this.searchOnMap(this._map, bounds)
      }
    }
  }

  searchOnMap(map, bounds){
    // SEARCH ON MAP -----------------
    // https://codepen.io/JaminQuimby/pen/vOXQrL

    let markers = []
    // Create the search box and link it to the UI element.
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(pacInput);

    let searchBox = new google.maps.places.SearchBox(pacInput);

    // [START region_getplaces]
    // Listen for the event fired when the user selects an item from the
    // pick list. Retrieve the matching places for that item.
    google.maps.event.addListener(searchBox, 'places_changed', function() {
      let places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      for (let i = 0, marker; marker = markers[i]; i++) {
        marker.setMap(null);
      }

      // For each place, get the icon, place name, and location.
      markers = [];
      //let newBounds = new google.maps.LatLngBounds();
      for (let i = 0, place; place = places[i]; i++) {
        let image = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(50, 50)
        };

        // Create a marker for each place.
        let marker = new google.maps.Marker({
          map: map,
          icon: image,
          title: place.name,
          position: place.geometry.location
        });

        markers.push(marker);

        bounds.extend(place.geometry.location);
      }

      map.fitBounds(bounds);
      map.setZoom(12)
    });
    // [END region_getplaces]

    // Bias the SearchBox results towards places that are within the bounds of the
    // current map's viewport.
    google.maps.event.addListener(map, 'bounds_changed', function() {
      searchBox.setBounds(bounds);
    });
  }
}
