import { Autocomplete } from "stimulus-autocomplete"

export default class extends Autocomplete {
  static targets = ["input", "hidden", "results", "unitOfMeasurement"]

  connect() {
    super.connect()
    document.addEventListener("autocomplete.change", this.autocomplete.bind(this))
  }

  autocomplete(event) {
    const index = event.target.parentNode.dataset.index
    const baseFields = "box_composition_box_composition_items_attributes"
    const minQuantity = event.detail.selected.dataset.autocompleteMinQuantity
    const maxQuantity = event.detail.selected.dataset.autocompleteMaxQuantity
    const unitOfMeasurement = event.detail.selected.dataset.autocompleteUnitOfMeasurement

    document.getElementById(`${baseFields}_${index}_min_quantity`).value = minQuantity
    document.getElementById(`${baseFields}_${index}_max_quantity`).value = maxQuantity
    document.getElementById(`${baseFields}_${index}_unit_of_measurement`).value = unitOfMeasurement
  }
}
