import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.adjustModalWindowSize()
  }

  initialize(){
    this.toggleRunSimulationButton()
    this.toggleSaveFilterButton()
    this.addToItemsToVarietyBoxFromFilter()

    document.addEventListener("turbo:frame-render", (event) => {
      const fetchResponse = event.detail.fetchResponse;

      if (fetchResponse.succeeded) {
        this.toggleRunSimulationButton()
        this.toggleSaveFilterButton()

        if(event.target.id === "simulation-results"){
          document.getElementById(event.target.id).scrollIntoView({ behavior: "smooth", block: "start"});
        }

        if(event.target.id === "products-results"){
          this.syncCheckBoxes();
          this.calculate()
        }
      }
    })

    document.addEventListener("turbo:submit-end", async (event) => {
      const responseSuccess = event.detail.success;
      const urlPath = event.detail.fetchResponse.response.url.split("/").pop();

      if (responseSuccess && urlPath === "box_planning_filters") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("modal-save-filters-button").click()
          document.getElementById("flash_notice_frame").scrollIntoView({ behavior: "smooth", block: "start"});
        }
      }

      if (responseSuccess && urlPath === "add_or_replace_items") {
        const headers = await event.detail.fetchResponse.response.headers

        if (headers.get("X-Mercado-Diferente") === "success") {
          document.getElementById("modal-replace-varieties-cancel-button").click()
        }
      }

      if (urlPath === "run_simulation") {
        const simulationButton = document.getElementById("submit-run-simulation-button")
        simulationButton.disabled = false
        simulationButton.children[1].innerText = "Rodar simulação"
      }
    })

    document.addEventListener("turbo:submit-start", async (event) => {
      const urlPath = await event.detail.formSubmission.fetchRequest.url.href.split("/").pop();

      if (urlPath === "run_simulation") {
        const simulationButton = document.getElementById("submit-run-simulation-button")
        simulationButton.disabled = true
        simulationButton.children[1].innerText = "Aguarde..."
      }
    })
  }

  calculate(){
    const allProductsRows = Array.from(document.getElementsByClassName("modal-product-row"))

    allProductsRows.forEach(productRow => {
      const inventoryTotalStr = "inventory_total"
      const portionCapabilityStr = "portion_capability"
      const abilityToUseStr = "ability_to_use"
      const remainingBalanceStr = "remaining_balance"

      const productClassificationCategory = productRow.dataset.productClassificationCategory
      const productId = productRow.dataset.productId
      const inventoryTotalValue = document.getElementById(`${inventoryTotalStr}_${productId}`).innerHTML.replace(",", ".")
      const portionCapabilityValue = document.getElementById(`${portionCapabilityStr}_${productId}`).innerHTML.replace(",", ".")
      const abilityToUseValue = document.getElementById(`${abilityToUseStr}_${productId}`).value.replace(",", ".") || 0

      let remainingBalanceValue = 0

      if(inventoryTotalValue && portionCapabilityValue && abilityToUseValue) {
        remainingBalanceValue = (portionCapabilityValue - abilityToUseValue).toFixed(2)
      }

      document.getElementById(`${remainingBalanceStr}_${productId}`).innerHTML = remainingBalanceValue
      this.updateHiddenFieldAndVarietyBoxText(productId, abilityToUseValue)
      this.recalculateTotalByProductClassificationCategory(productClassificationCategory)
    })
  }

  updateHiddenFieldAndVarietyBoxText(productId, abilityToUseValue){
    const hiddenFieldElm = document.querySelector(`#hidden_field_${productId}`)
    const textSpanliProductElm = document.querySelector(`#text-span-li-product-${productId}`)
    const li = document.getElementById(`li-product-${productId}`)

    if (hiddenFieldElm) {
      hiddenFieldElm.value = `${abilityToUseValue}|||${li.dataset.itemPosition}`
      textSpanliProductElm.innerHTML = `${textSpanliProductElm.dataset.productName} - ${abilityToUseValue}`
      li.dataset.portionAmount = abilityToUseValue
    }
  }

  checkBoxClick(event){
    const checkBoxElm = event.target
    const productId = event.target.dataset.productId
    const productRow = event.target.parentElement.parentElement.parentElement
    const li = document.getElementById(`li-product-${productId}`)

    if (checkBoxElm.checked){
      this.addToVarietyBox(productRow)
    } else {
      this.removeVariety(li)
    }
  }

  syncCheckBoxes(){
    const hiddenInputsElm = document.querySelectorAll(".hidden-fields-container > input")

    if (hiddenInputsElm){
      hiddenInputsElm.forEach(hiddenInputElem => {
        const productId = hiddenInputElem.dataset.productId
        const checkBoxElm = document.getElementById(`checkbox_${productId}`)

        if (checkBoxElm){
          document.getElementById(`ability_to_use_${productId}`).value = hiddenInputElem.value.split("|||")[0]
          checkBoxElm.checked = true
        }
      })
    }
  }

  isSomeCheckBoxChecked(){
    return document.querySelectorAll("#modal-form-results input:checked").length > 0
  }

  addToVarietyBox(productRow){
    const productClassificationStr = "product_classification"
    const abilityToUseStr = "ability_to_use"

    const productId = productRow.dataset.productId
    const productName = productRow.dataset.productName

    const productClassificationCategory = productRow.dataset.productClassificationCategory
    const productClassificationList = document.getElementById(`${productClassificationStr}_${productClassificationCategory}`)

    const abilityToUseValue = document.getElementById(`${abilityToUseStr}_${productId}`).value.replace(",", ".")
    const itemPosition = new Date().toISOString().replace(/[^\d]/gi,'')

    if (productClassificationList){
      this.removeDefaultLis(productClassificationList)
      this.removeExistingtLi(productId)
      this.appendNewLi(productClassificationList, productId, productName, abilityToUseValue, itemPosition)
      this.calculateTotalProductClassification(productClassificationList, productClassificationCategory)
      this.generateHiddenField(productClassificationList, productId)
    }
  }

  addToItemsToVarietyBoxFromFilter(){
    const productClassificationStr = "product_classification"
    const searchParams = document.getElementById("search-params")

    if (!searchParams) return

    const productsAndCategoriesFromFilter = searchParams.dataset.productsAndCategoriesFromFilter
    const productsAndCategoriesArr =  productsAndCategoriesFromFilter.split(":::")

    if (searchParams.dataset.applyFilter === "true"){
      productsAndCategoriesArr.forEach(productIdAndCategorie => {
        const [productId, productName, abilityToUseValue, productClassificationCategory, itemPosition] = productIdAndCategorie.split("|||")
        const productClassificationList = document.getElementById(`${productClassificationStr}_${productClassificationCategory}`)

        if (productClassificationList){
          this.removeDefaultLis(productClassificationList)
          this.removeExistingtLi(productId)
          this.appendNewLi(productClassificationList, productId, productName, abilityToUseValue, itemPosition)
          this.calculateTotalProductClassification(productClassificationList, productClassificationCategory)
          this.generateHiddenField(productClassificationList, productId)
        }
      })
    }

    searchParams.dataset.applyFilter = "false";
  }

  removeDefaultLis(productClassificationList){
    const defaultLis = Array.from(productClassificationList.getElementsByClassName("remove-default-li"))

    defaultLis.forEach(element => {
      element.remove()
    })
  }

  addDefaultLi(productClassificationList){
    const li = document.createElement("li");
    const textSpan = document.createElement("span")

    textSpan.innerHTML = "----"
    textSpan.className = "px-1 variety-name"

    li.dataset.confirmed = false
    li.className = "bg-white text-left text-xs rounded-md p-1 mb-1 flex justify-between remove-default-li"

    li.appendChild(textSpan)

    productClassificationList.appendChild(li)
  }

  removeExistingtLi(productId){
    const oldLi = document.getElementById(`product_${productId}`)
    if (oldLi) { oldLi.remove() }
  }

  appendNewLi(productClassificationList, productId, productName, abilityToUseValue, itemPosition){
    const liProductStr = "li-product"
    const li = document.createElement("li");
    const oldLi = document.getElementById(`${liProductStr}-${productId}`)
    const textSpan = document.createElement("span")
    const textSpanStr = `text-span-${liProductStr}-${productId}`
    const divWrapper = document.createElement("div")
    const divElement = document.createElement("div")
    const btnChangeAmountSpan = document.createElement("span")
    const btnRemoveSpan = document.createElement("span")

    // Edit amount component
    const divEditAmount = document.createElement("div")
    const inputSaveAmount = document.createElement("input")
    const divSaveAmount = document.createElement("div")
    const btnSaveAmountSpan = document.createElement("span")
    const divEditAmountNameStr = `edit-amount-${liProductStr}-${productId}`
    const inputSaveAmountStr = `input-new-value-${liProductStr}-${productId}`

    textSpan.innerHTML = `${productName} - ${abilityToUseValue}`
    textSpan.setAttribute("id", textSpanStr)
    textSpan.dataset.productName = productName
    textSpan.className = "px-1 variety-name max-w-3/5"

    btnChangeAmountSpan.innerHTML = "✍︎"
    btnChangeAmountSpan.className = "bg-indigo-200 rounded-md px-1 text-white cursor-pointer mr-1"
    btnChangeAmountSpan.dataset.divEditAmountId = divEditAmountNameStr
    btnChangeAmountSpan.dataset.action = "click->planning-boxes--planning-boxes#changeAmount"

    btnRemoveSpan.innerHTML = "Remover"
    btnRemoveSpan.className = "bg-orange-400 rounded-md px-1 text-white cursor-pointer"
    btnRemoveSpan.dataset.action = "click->planning-boxes--planning-boxes#prepareToRemoveVariety"

    // Edit amount component
    divEditAmount.className = "flex justify-between mt-1 max-w-30 place-self-center hidden"
    divEditAmount.setAttribute("id", divEditAmountNameStr)

    inputSaveAmount.className = "form-input pl-2 max-h-6 text-xs text-slate-500 hover:text-slate-600 focus:border-slate-300 w-3/4"
    inputSaveAmount.setAttribute("id", inputSaveAmountStr)
    inputSaveAmount.value = abilityToUseValue

    divSaveAmount.className = "w-2/4 text-center place-self-center ml-2"

    btnSaveAmountSpan.innerHTML = "Salvar"
    btnSaveAmountSpan.className = "bg-indigo-400 rounded-md px-1 text-white cursor-pointer"
    btnSaveAmountSpan.dataset.liId = `${liProductStr}-${productId}`
    btnSaveAmountSpan.dataset.inputId = inputSaveAmountStr
    btnSaveAmountSpan.dataset.textSpanId = textSpanStr
    btnSaveAmountSpan.dataset.action = "click->planning-boxes--planning-boxes#saveAmount"

    divSaveAmount.appendChild(btnSaveAmountSpan)
    divEditAmount.appendChild(inputSaveAmount)
    divEditAmount.appendChild(divSaveAmount)

    if (oldLi) { oldLi.remove() }

    divElement.appendChild(btnChangeAmountSpan)
    divElement.appendChild(btnRemoveSpan)

    li.setAttribute("id", `${liProductStr}-${productId}`)
    li.dataset.productId = productId
    li.dataset.portionAmount = abilityToUseValue
    li.dataset.itemPosition = itemPosition
    li.className = "flex flex-col bg-white text-left text-xs rounded-md p-1 mb-1 flex justify-between variety-li"

    divWrapper.className = "flex justify-between"
    divWrapper.appendChild(textSpan)
    divWrapper.appendChild(divElement)

    li.appendChild(divWrapper)
    li.appendChild(divEditAmount)

    productClassificationList.appendChild(li)
    this.sortProductClassificationList(productClassificationList)
  }

  sortProductClassificationList(productClassificationList) {
    let lis = productClassificationList.getElementsByTagName("li")
    let lisArray = Array.from(lis);
    let sorted = lisArray.sort((a, b) => {
      if (a.dataset.itemPosition < b.dataset.itemPosition)
          return -1;
      if (a.dataset.itemPosition > b.dataset.itemPosition)
          return 1;
      return 0;
    })

    sorted.forEach(e => productClassificationList.appendChild(e))
  }

  changeAmount(event){
    const divEditAmountElm = document.getElementById(event.target.dataset.divEditAmountId)
    divEditAmountElm.classList.toggle("hidden");
  }

  saveAmount(event){
    const liElement = document.getElementById(event.target.dataset.liId);
    const inputElement = document.getElementById(event.target.dataset.inputId)
    const textSpanElement = document.getElementById(event.target.dataset.textSpanId)
    const ul = liElement.parentElement
    const productClassificationCategory = ul.dataset.productClassificationCategory
    const hiddenField = document.querySelector(`input[name='${productClassificationCategory}[${liElement.dataset.productId}]']`)

    liElement.dataset.portionAmount = inputElement.value
    hiddenField.value = inputElement.value
    textSpanElement.innerHTML = `${textSpanElement.dataset.productName} - ${liElement.dataset.portionAmount}`
    inputElement.parentElement.classList.toggle("hidden")

    this.recalculateTotalByProductClassificationCategory(productClassificationCategory)
  }

  calculateTotalProductClassification(productClassificationList, productClassificationCategory){
    const totalProductClassificationStr = "total_product_classification"
    const lis = productClassificationList.getElementsByTagName("li")
    let sum = 0.0

    Array.from(lis).forEach(li => {
      sum += parseFloat(li.dataset.portionAmount.replace(",", "."))
    })

    document.getElementById(`${totalProductClassificationStr}_${productClassificationCategory}`).innerHTML = `Você consegue servir ${sum.toFixed(2)} porções`
    this.toggleRunSimulationButton()
    this.toggleSaveFilterButton()
  }

  generateHiddenField(productClassificationList, productId){
    const liProductStr = "li-product"
    const productClassificationCategory = productClassificationList.dataset.productClassificationCategory
    const hiddenFieldsContainerStr = "hidden-fields-container"
    const [hiddenFieldsContainer] = productClassificationList.parentElement.getElementsByClassName(hiddenFieldsContainerStr)
    const [oldHiddenField] = document.getElementsByName(`${productClassificationCategory}[${productId}]`)
    const hiddenInput = document.createElement("input");
    const li = document.getElementById(`${liProductStr}-${productId}`)

    if (oldHiddenField) { oldHiddenField.remove() }

    hiddenInput.setAttribute("id", `hidden_field_${productId}`);
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", `${productClassificationCategory}[${productId}]`);
    hiddenInput.setAttribute("value", `${li.dataset.portionAmount.replace(",", ".")}|||${li.dataset.itemPosition}`);

    hiddenInput.dataset.productId = productId
    hiddenInput.dataset.productClassificationCategory = productClassificationCategory

    hiddenFieldsContainer.appendChild(hiddenInput)
  }

  showErrorMessage(message){
    const htmlMessage = `
      <div data-controller="flash" class="px-4 py-2 rounded-sm text-sm border border-rose-200 text-rose-600 bg-rose-100" role="alert">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"></path>
            </svg>
            <div>${message}</div>
          </div>
          <button class="opacity-70 hover:opacity-80 ml-3 mt-[3px]" data-action="flash#dismiss">
            <div class="sr-only">Close</div>
            <svg class="w-4 h-4 fill-current">
              <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"></path>
            </svg>
          </button>
        </div>
      </div>
    `

    document.getElementById("modal-variety-error-messages").innerHTML = htmlMessage
  }

  prepareToRemoveVariety(event){
    const div = event.target.parentElement.parentElement
    const li = div.parentElement
    const varietyNameElements = li.getElementsByClassName("variety-name")

    // Pega o primeiro elemento do array atribuído
    // [xyz] = array
    const [varietyNameElement] = varietyNameElements

    if (varietyNameElement.classList.contains("hidden")) {
      this.removeVariety(li)
    } else {
      varietyNameElement.classList.add("hidden")
      this.showRemoveConfirmation(event.target);
    }
  }

  removeVariety(li){
    const ul = li.parentElement
    const productClassificationCategory = ul.dataset.productClassificationCategory
    const [hiddenField] = document.getElementsByName(`${productClassificationCategory}[${li.dataset.productId}]`)

    li.remove()
    hiddenField.remove()

    this.recalculateTotalByProductClassificationCategory(productClassificationCategory)

    if (Array.from(ul.getElementsByTagName("li")).length === 0) {
      this.addDefaultLi(ul)
    }
  }

  showRemoveConfirmation(element){
    const textSpan = document.createElement("span")
    const btnNoSpan = document.createElement("span")
    const div =  element.parentElement
    const li =  div.parentElement

    textSpan.innerHTML = "Tem certeza?"
    textSpan.className = "px-1 are-you-ready"
    li.prepend(textSpan)

    btnNoSpan.innerHTML = "Não"
    btnNoSpan.className = "bg-orange-400 rounded-md px-1 ml-1 text-white cursor-pointer"
    btnNoSpan.dataset.action = "click->planning-boxes--planning-boxes#cancelRemoveVariety"
    div.appendChild(btnNoSpan)
  }

  cancelRemoveVariety(event){
    const div = event.target.parentElement
    const li = div.parentElement
    const varietyNameElements = li.getElementsByClassName("variety-name")
    const questionElements = li.getElementsByClassName("are-you-ready")

    // Pega o primeiro elemento do array atribuído
    // [xyz] = array
    const [varietyNameElement] = varietyNameElements
    const [questionElement] = questionElements

    varietyNameElement.classList.remove("hidden")
    questionElement.remove()
    event.target.remove()
  }

  recalculateTotalByProductClassificationCategory(productClassificationCategory){
    const productClassificationStr = "product_classification"
    const productClassificationList = document.getElementById(`${productClassificationStr}_${productClassificationCategory}`)
    this.calculateTotalProductClassification(productClassificationList, productClassificationCategory)
  }

  toggleRunSimulationButton(){
    const productClassificationLists = Array.from(document.getElementsByClassName("product-classification-list"))
    let allowSubmit = true

    productClassificationLists.forEach(ul => {
      if (Array.from(ul.getElementsByClassName("variety-li")).length === 0) {
        allowSubmit = false
      }
    })

    if (allowSubmit) {
      this.enableRunSimulationButton()
    } else {
      this.disbaleRunSimulationButton()
    }
  }

  disbaleRunSimulationButton(){
    const submitRunSimulationButton = document.getElementById("submit-run-simulation-button")

    submitRunSimulationButton.disabled = true
    submitRunSimulationButton.classList.add("opacity-25")
  }

  enableRunSimulationButton(){
    const submitRunSimulationButton = document.getElementById("submit-run-simulation-button")

    if (submitRunSimulationButton){
      submitRunSimulationButton.disabled = false
      submitRunSimulationButton.classList.remove("opacity-25")
    }
  }

  toggleSaveFilterButton(){
    const productClassificationLists = Array.from(document.getElementsByClassName("product-classification-list"))
    let allowClick = false

    productClassificationLists.forEach(ul => {
      if (Array.from(ul.getElementsByClassName("variety-li")).length > 0) {
        allowClick = true
      }
    })

    if (allowClick) {
      this.enableSaveFiltersButton()
    } else {
      this.disbaleSaveFiltersButton()
    }
  }

  disbaleSaveFiltersButton(){
    const saveFiltersButton = document.getElementById("save-filters-button")

    if (saveFiltersButton) {
      saveFiltersButton.disabled = true
      saveFiltersButton.classList.add("opacity-25")
    }
  }

  enableSaveFiltersButton(){
    const saveFiltersButton = document.getElementById("save-filters-button")

    if (saveFiltersButton) {
      saveFiltersButton.disabled = false
      saveFiltersButton.classList.remove("opacity-25")
    }
  }

  adjustModalWindowSize(){
    const dynamicModalWindow = document.getElementById("dynamic-modal-window")

    if (dynamicModalWindow) {
      const dynamicModalWindowHeight = dynamicModalWindow.offsetHeight
      const dynamicModalWindowWidth = dynamicModalWindow.offsetWidth
      const dynamicModalWindowHeaderHeight = document.getElementById("dynamic-modal-window-header").offsetHeight
      const dynamicModalWindowSearchHeight = document.getElementById("dynamic-modal-window-search").offsetHeight
      const dynamicModalWindowFooterHeight = document.getElementById("dynamic-modal-window-footer").offsetHeight
      const dynamicModalWindowMessage = document.getElementById("dynamic-modal-window-message")
      const finalSize = Math.ceil(dynamicModalWindowHeight - (dynamicModalWindowHeaderHeight + dynamicModalWindowFooterHeight + dynamicModalWindowSearchHeight))
      const dynamicScrollArea = document.getElementById("dynamic-scroll-area")

      if (dynamicScrollArea){
        dynamicScrollArea.className = (`h-${finalSize}px max-h-${finalSize}px overflow-y-scroll`)
        dynamicScrollArea.style.maxHeight = `${finalSize}px`
        dynamicScrollArea.style.height = `${finalSize}px`
      }

      if (dynamicModalWindowMessage){
        dynamicModalWindowMessage.style.top = `${(Math.ceil(dynamicModalWindowHeight / 3) * 2) - 10}px`
        dynamicModalWindowMessage.style.right = `${Math.ceil(dynamicModalWindowWidth / 2) - (dynamicModalWindowMessage.offsetWidth / 2)}px`
      }
    }
  }
}
