import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkBoxPrintExtraLabel", "printRemaingLabel", "printExtraLabel" ]

  togglePrintExtraLabel() {
    if (this.checkBoxPrintExtraLabelTarget.checked) {
      this.printRemaingLabelTarget.classList.add('hidden')
      this.printExtraLabelTarget.classList.remove('hidden')
    } else {
      this.printRemaingLabelTarget.classList.remove('hidden')
      this.printExtraLabelTarget.classList.add('hidden')
    }
  }
}
