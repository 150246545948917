import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.adjustModalWindowSize()
  }

  adjustModalWindowSize(){
    const dynamicModalWindow = document.getElementById("dynamic-modal-window")

    if (dynamicModalWindow) {
      const dynamicModalWindowHeight = dynamicModalWindow.offsetHeight
      const dynamicModalWindowWidth = dynamicModalWindow.offsetWidth
      const dynamicModalWindowHeaderHeight = document.getElementById("dynamic-modal-window-header").offsetHeight
      const dynamicModalWindowSearchHeight = document.getElementById("dynamic-modal-window-search").offsetHeight
      const dynamicModalWindowFooterHeight = document.getElementById("dynamic-modal-window-footer").offsetHeight
      const dynamicModalWindowMessage = document.getElementById("dynamic-modal-window-message")
      const finalSize = Math.ceil(dynamicModalWindowHeight - (dynamicModalWindowHeaderHeight + dynamicModalWindowFooterHeight + dynamicModalWindowSearchHeight))
      const dynamicScrollArea = document.getElementById("dynamic-scroll-area")

      if (dynamicScrollArea){
        dynamicScrollArea.className = (`h-${finalSize}px max-h-${finalSize}px overflow-y-scroll`)
        dynamicScrollArea.style.maxHeight = `${finalSize}px`
        dynamicScrollArea.style.height = `${finalSize}px`
      }

      if (dynamicModalWindowMessage){
        dynamicModalWindowMessage.style.top = `${(Math.ceil(dynamicModalWindowHeight / 3) * 2) - 10}px`
        dynamicModalWindowMessage.style.right = `${Math.ceil(dynamicModalWindowWidth / 2) - (dynamicModalWindowMessage.offsetWidth / 2)}px`
      }
    }
  }

  triggerSelectedProductsForm() {
    document.getElementById("modal-form-results").submit()
  }
}
