import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["totalQuantity", "totalRealized", "averagePortions",
                    "totalPortionsDone", "resultingBoxes", "quantityPerBox",
                    "totalPortions", "itemsRow", "toBeRealized", "minPortionQuantity",
                    "maxPortionQuantity", "productMeasurement", "addNewRowButton",
                    "submitFormButton"]

  initialize(){
    this.calculate()
  }

  isEmpty = str => !str.trim().length

  calculate(){
    this.calculateToBeRealized()
    this.calculateTotalRealized()
    this.calculateTotalPortions()
    this.calculateTotalPortionsDone()
    this.calculateAveragePortions()
    this.checkAddNewRowButton()
    this.validateForms()
  }

  checkAddNewRowButton(){
    // const totalParentBoxes = parseFloat(this.toBeRealizedTarget.dataset.totalParentBoxes, 10)
    // const totalRealized = parseFloat(this.totalRealizedTarget.innerText, 10)
    if(this.hasAddNewRowButtonTarget) {
      const toBeRealized = parseFloat(this.toBeRealizedTarget.innerText, 10)

      if (toBeRealized > 0){
        this.addNewRowButtonTarget.style.display = "block"
      } else {
        this.addNewRowButtonTarget.style.display = "none"
      }
    }
  }

  validateForms(){
    this.itemsRowTargets.forEach((element) => {
      this.validateForm(element)
    })
  }

  validateForm(element){
    const totalQuantityElm = element.querySelector("input[name$='[total_quantity]']")
    const resultingBoxesElm = element.querySelector("input[name$='[resulting_boxes]']")
    const quantityPerBoxElm = element.querySelector("input[name$='[quantity_per_box]']")
    const operatorsElm = element.querySelector("input[name$='[operators]']")

    this.isEmpty(totalQuantityElm.value) ? totalQuantityElm.style.borderColor = "red" : totalQuantityElm.style.borderColor = ""
    this.isEmpty(resultingBoxesElm.value) ? resultingBoxesElm.style.borderColor = "red" : resultingBoxesElm.style.borderColor = ""
    this.isEmpty(quantityPerBoxElm.value) ? quantityPerBoxElm.style.borderColor = "red" : quantityPerBoxElm.style.borderColor = ""
    this.isEmpty(operatorsElm.value) ? operatorsElm.style.borderColor = "red" : operatorsElm.style.borderColor = ""

    if(this.hasSubmitFormButtonTarget) {
      this.submitFormButtonTarget.disabled = true
      this.submitFormButtonTarget.style.cursor = "not-allowed"
      this.submitFormButtonTarget.style.opacity = "0.5"
    }

    if (this.isEmpty(operatorsElm.value) || !this.hasSubmitFormButtonTarget) {
      return false
    } else {
      this.submitFormButtonTarget.disabled = false
    }

    if(this.isEmpty(totalQuantityElm.value) || this.isEmpty(resultingBoxesElm.value) || this.isEmpty(quantityPerBoxElm.value)) {
      return false
    }

    if (parseFloat(this.toBeRealizedTarget.innerText, 10) < 0){
      return false
    }

    // Média de KG por porção
    // if(this.productMeasurementTarget.innerText !== "UND:"){
    //   if (
    //     ((parseFloat(this.averagePortionsTarget.innerText.replace(/,/g, '.'), 10) || 0) >= (parseFloat(this.minPortionQuantityTarget.innerText.replace(/,/g, '.'), 10) || 0)) &&
    //     ((parseFloat(this.averagePortionsTarget.innerText.replace(/,/g, '.'), 10) || 0) <= (parseFloat(this.maxPortionQuantityTarget.innerText.replace(/,/g, '.'), 10) || 0))
    //   ){
    //     // true
    //   } else {
    //     return false
    //   }
    // }

    this.submitFormButtonTarget.style.cursor = "pointer"
    this.submitFormButtonTarget.style.opacity = "1"

    return true
  }

  disableSubmit(){

  }

  calculateToBeRealized(){
    let sumTotalRealized = 0
    const totalParentBoxes = parseFloat(this.toBeRealizedTarget.dataset.totalParentBoxes, 10)

    this.totalQuantityTargets.forEach((element) => {
      sumTotalRealized += parseFloat(element.value || 0, 10)
    })

    this.toBeRealizedTarget.innerText = parseFloat(totalParentBoxes - sumTotalRealized, 10)
  }

  calculateTotalRealized(){
    let sumTotalRealized = 0

    this.totalQuantityTargets.forEach((element) => {
      sumTotalRealized += parseFloat(element.value || 0, 10)
    })

    this.totalRealizedTarget.innerText = sumTotalRealized

    if (parseFloat(this.toBeRealizedTarget.innerText, 10) >= 0){
      this.totalRealizedTarget.classList.remove("text-rose-500", "bg-rose-100")
      this.totalRealizedTarget.classList.add("text-green-500", "bg-green-100")
    } else {
      this.totalRealizedTarget.classList.remove("text-green-500", "bg-green-100")
      this.totalRealizedTarget.classList.add("text-rose-500", "bg-rose-100")
    }
  }

  calculateTotalPortions(){
    let sumTotalPortions = 0

    this.itemsRowTargets.forEach((element) => {
      const resultingBoxes = parseInt(element.querySelector("input[name$='[resulting_boxes]']").value || 0, 10)
      const quantityPerBox = parseInt(element.querySelector("input[name$='[quantity_per_box]']").value || 0, 10)
      const destroyInputValue = element.querySelector("input[name*='_destroy']").value

      if (destroyInputValue === "1") {
        return // next
      }

      sumTotalPortions =  resultingBoxes * quantityPerBox

      element.querySelector("[data-pre-pickings--manage-quantities-target='totalPortions']").innerText = sumTotalPortions
      element.querySelector("input[name$='[total_portions]']").value = sumTotalPortions
    })
  }

  calculateTotalPortionsDone(){
    let sumTotalPortions = 0

    this.totalPortionsTargets.forEach((element) => {
      sumTotalPortions += parseFloat(element.innerText || 0, 10)
    })

    this.totalPortionsDoneTarget.innerText = sumTotalPortions
  }

  calculateAveragePortions(){
    let sumTotalAverage = 0

    this.itemsRowTargets.forEach((element) => {
      const totalRealized = parseFloat(this.totalRealizedTarget.innerText, 10)
      const totalPortionsDone = parseFloat(this.totalPortionsDoneTarget.innerText, 10)
      const destroyInputValue = element.querySelector("input[name*='_destroy']").value

      if((totalRealized === 0) || (totalPortionsDone === 0)){
        return // next
      }

      if (destroyInputValue === "1") {
        return // next
      }

      sumTotalAverage +=  totalRealized / totalPortionsDone
    })

    let result = ((sumTotalAverage || 0) / this.itemsRowTargets.length)

    if (!isFinite(result)) {
      this.averagePortionsTarget.innerText = 0
    } else {
      this.averagePortionsTarget.innerText = result.toFixed(2)
    }

    if(this.productMeasurementTarget.innerText !== "UND:") {
      if (
          ((parseFloat(this.averagePortionsTarget.innerText.replace(/,/g, '.'), 10) || 0) >= (parseFloat(this.minPortionQuantityTarget.innerText.replace(/,/g, '.'), 10) || 0)) &&
          ((parseFloat(this.averagePortionsTarget.innerText.replace(/,/g, '.'), 10) || 0) <= (parseFloat(this.maxPortionQuantityTarget.innerText.replace(/,/g, '.'), 10) || 0))
        ){
        this.averagePortionsTarget.classList.add("text-green-500", "bg-green-100")
        this.averagePortionsTarget.classList.remove("text-rose-500", "bg-rose-100")
      } else {
        this.averagePortionsTarget.classList.add("text-rose-500", "bg-rose-100")
        this.averagePortionsTarget.classList.remove("text-green-500", "bg-green-100")
      }
    }
  }

  submit(event){
    event.preventDefault()

    if(this.validateForm(this.element)){
      this.element.requestSubmit()
    }
  }
}
