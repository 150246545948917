import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Controller } from "@hotwired/stimulus"

// import Highcharts from 'highcharts'
// import 'highcharts/highcharts-more'
// import 'highcharts/modules/accessibility'
// import 'highcharts/modules/funnel'

export default class extends Controller {
  static values = { labels: Array, data: Array }

  initialize() {
    const chart = new Chart(document.getElementById("sign-up-canvas").getContext("2d"), {
      type: "funnel",
      data: {
        labels: this.labelsValue,
        datasets: [
          {
            data: this.dataValue,
            // backgroundColor: chroma.scale('Greens').colors(this.dataValue.length),
            shrinkAnchor: "middle",
            // shrinkFraction: 0.5,
          },
        ],
      },
      options: {
        indexAxis: "y",
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            // textAlign: "left",
            // align: "center",
            // display: true,
            // clip: true,
            // clamp: true,
            // rotation: 90,
            labels: {
              title: {
                color: "black",
                font: {
                  weight: "bold",
                  size: 12
                }
              },
              // value: {
              //   color: "red",
              //   size: 10
              // }
            },
            // formatter: function(value, context) {
            //   return context.chart.data.labels[context.dataIndex]
            // }
          }
        }
      },
      plugins: [ChartDataLabels],
    });
  }

  // initialize() {
  //   Highcharts.chart(document.getElementById("sign-up-canvas"), {
  //     chart: {
  //         type: 'funnel'
  //     },
  //     title: {
  //         text: 'Sales funnel'
  //     },
  //     plotOptions: {
  //         series: {
  //             dataLabels: {
  //                 enabled: true,
  //                 format: '<b>{point.name}</b> ({point.y:,.0f})',
  //                 softConnector: true
  //             },
  //             center: ['40%', '50%'],
  //             neckWidth: '30%',
  //             neckHeight: '25%',
  //             width: '80%'
  //         }
  //     },
  //     legend: {
  //         enabled: false
  //     },
  //     series: [{
  //         name: 'Unique users',
  //         data: [
  //             ['Website visits', 15654],
  //             ['Downloads', 4064],
  //             ['Requested price list', 1987],
  //             ['Invoice sent', 976],
  //             ['Finalized', 846]
  //         ]
  //     }],

  //     responsive: {
  //         rules: [{
  //             condition: {
  //                 maxWidth: 500
  //             },
  //             chartOptions: {
  //                 plotOptions: {
  //                     series: {
  //                         dataLabels: {
  //                             inside: true
  //                         },
  //                         center: ['50%', '50%'],
  //                         width: '100%'
  //                     }
  //                 }
  //             }
  //         }]
  //     }
  // });
  // }
}
