import { Controller } from "@hotwired/stimulus"

// Reference: https://mentalized.net/journal/2020/11/30/upload-multiple-files-with-rails/

export default class extends Controller {
  static targets = ["files"]

  addFile(event) {
    const originalInput = event.target
    const originalParent = originalInput.parentNode
    const selectedFile = document.createElement("div")
    const newInput = originalInput.cloneNode()
    const labelNode = document.createElement("label")
    const textElement = document.createTextNode(originalInput.files[0].name)

    selectedFile.className = "selected-file"
    selectedFile.append(originalInput)
    labelNode.appendChild(textElement)
    selectedFile.appendChild(labelNode)
    this.filesTarget.append(selectedFile)
    newInput.value = ""
    originalParent.append(newInput)
  }
}
