import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    if (window.location.search.includes("?modal=true")) {
      window.history.pushState({}, document.title, window.location.pathname); // remove query string form url
      document.getElementsByClassName("inventory-entry-modal-button")[0].click();
    }
  }
}
