import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]

  handleChangeCheckbox() {
    const [,,,,index] = this.checkboxTarget.id.split("_");
    if(this.checkboxTarget.checked) {
      document.getElementById(`geofence_location_availabilities_attributes_${index}__destroy`).value = false
    } else {
      document.getElementById(`geofence_location_availabilities_attributes_${index}_max_orders_morning`).value = null
      document.getElementById(`geofence_location_availabilities_attributes_${index}_max_orders_afternoon`).value = null
      document.getElementById(`geofence_location_availabilities_attributes_${index}__destroy`).value = true
    }
  }
}
