import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["deliveryDate", "paymentDate"]

  updatePaymentDate() {
    const deliveryDateValues = this.deliveryDateTarget.value.split("/")

    let dateBasedOnDelivery = new Date(
      deliveryDateValues[2],
      deliveryDateValues[1] - 1, // the month is 0-indexed
      deliveryDateValues[0]
    )

    const newPaymentDate = new Date(
      dateBasedOnDelivery.setDate(dateBasedOnDelivery.getDate() - 4)
    )

    const day = newPaymentDate.getDate()
    const newPaymentDay = day <= 9 ? `${0}${day}` : day

    const month = newPaymentDate.getMonth() + 1 // the month is 0-indexed
    const newPaymentMonth = month <= 9 ? `${0}${month}` : month

    this.paymentDateTarget.value = `${newPaymentDay}/${newPaymentMonth}/${newPaymentDate.getFullYear()}`
  }
}
